import { format } from 'date-fns';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import ModulesList from '../../../../admin/responsibility/searchModule';
import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
import {
  required,
  integer,
  maxValue,
  minValue
} from 'vuelidate/lib/validators';
export default {
  name: 'AddHostToHostBankTransfer',
  components: {
    DatePicker,
    party,
    ModulesList,
    ModelSelect
  },
  data() {
    return {
      loader: false,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      tab: null,
      isViewMore: false,
      showModal: false,
      selectedModule: {
        name: null,
        id: null
      },
      batchName: null,
      scheduleType: {
        value: null,
        text: null
      },
      batchId: 0,
      paymentPriority: 99,
      showPartyModal: false,
      paymentMethod: {
        value: null,
        text: null
      },
      paymentMethodList: [],
      paymentBasedBanks: [],
      paymentDate: null,
      paymentMode: null,
      paymentModeCode: null,
      bankName: null,
      branchName: null,
      accountName: null,
      paymentBatch: {
        value: null,
        text: null
      },
      paymentBatchList: [],
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      parent_value_set_id: null,
      showValueSetModal: false,
      lastUpdatedLogin: null,
      lastUpdatedBy: null,
      dueFromToDate: [],
      payGroup: {
        value: null,
        text: null
      },
      party: {
        value: null,
        text: null
      },
      partyType: null,
      status: 'DRAFT',
      remarks: null,
      creationDate: format(new Date(), appStrings.DATEFNSFORMAT),
      createdBy: null,
      invoiceDetails: [],
      paymentDetails: [],
      paymentList: [
        {
          invoice: 'invoice',
          batch_name: 'Test',
          payment_type: 'N/A',
          instrument_status: 'Negotiable',
          parent_value_set_id: null,
          invoicesList: [
            {
              module_name: 'N/A'
            }
          ]
        }
      ],
      paymentFields: [
        {
          key: 'invoice'
        },
        {
          key: 'selection_flag',
          label: 'Select'
        },
        {
          key: 'status_flag',
          label: 'Status'
        },
        {
          key: 'payment_document_no',
          label: 'Payment Doc No.'
        },
        {
          key: 'payment_ref_no'
        },
        {
          key: 'party_name'
        },
        {
          key: 'party_type'
        },
        {
          key: 'party_site_name',
          label: 'Party Site'
        },
        {
          key: 'instrument_status_meaning',
          label: 'Instrument Status'
        },
        {
          key: 'payment_amount'
        },
        {
          key: 'currency'
        },
        {
          key: 'exchange_rate'
        },
        {
          key: 'exchange_rate_type'
        },
        {
          key: 'actual_bank_exchange_rate'
        },
        {
          key: 'actual_bank_exchange_rate_date'
        },
        {
          key: 'payment_date'
        },
        {
          key: 'beneficiary_name'
        },
        {
          key: 'bank_account_number',
          label: 'Bank Acc No.'
        },
        {
          key: 'ifsc_code',
          label: 'IFSC Code'
        },
        {
          key: 'utr_no',
          label: 'UTR No.'
        },
        {
          key: 'void_date'
        },
        {
          key: 'void_gl_date',
          label: 'Void GL Date'
        },
        {
          key: 'remarks'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        }
      ],
      invoicesList: [],
      invoicesFields: [
        {
          key: 'selection_flag',
          label: 'Select'
        },
        {
          key: 'status_flag',
          label: 'Status'
        },
        {
          key: 'invoice_number'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'invoice_amount'
        },
        {
          key: 'schedule_number'
        },
        {
          key: 'schedule_line_type_meaning',
          label: 'Schedule Line Type'
        },
        {
          key: 'schedule_amount'
        },
        {
          key: 'payment_amount',
          label: 'Paid in this Payment'
        }
      ],
      project: {
        value: null,
        text: null
      },
      depVsetParam: null
    };
  },
  validations: {
    // leName: { text: { required } },
    // ouName: { text: { required } },
    paymentMethod: { text: { required } },
    payGroup: { text: { required } },
    // paymentMode: { text: { required } },
    dueFromToDate: { required },
    paymentPriority: {
      required,
      integer,
      minValue: minValue(1),
      maxValue: maxValue(99)
    }
  },
  mounted() {
    this.setDefaultPaymentMode();
    this.getPaymentBatchLov();
    if (this.batchPaymentDetails) {
      this.getBatchPaymentHdrDetails(this.batchPaymentDetails);
    } else {
      this.editMode = true;
      this.isViewMore = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          if (this.status === 'DRAFT') {
            this.addBankPaymentDetails();
          }
        }
      }
    });
  },
  methods: {
    addBankPaymentDetails() {
      // to add new bank payment
      const payload = {
        batch_status: this.status,
        payment_batch_id: this.paymentBatch.value,
        le_id: this.leName.value,
        ou_id: this.ouName.value,
        party_id: this.party.value,
        party_type: this.partyType,
        payment_date_from: commonHelper.formattedDate(this.dueFromToDate[0]),
        payment_date_to: commonHelper.formattedDate(this.dueFromToDate[1]),
        payment_method_id: this.paymentMethod.value,
        payment_mode_vset: 'EFT',
        remarks: this.remarks
      };
      this.loader = true;
      this.$store
        .dispatch('hostToHostBankTransfer/addBankPaymentDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.batchName = response.data.data;
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getPaymentBatchLov() {
      // to open valuset payment batch
      this.loader = true;
      this.$store
        .dispatch('hostToHostBankTransfer/getPaymentBatchLov')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.paymentBatchList = results.map(key => {
              return {
                value: key.payment_batch_id,
                text: key.payment_batch_name
              };
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditPaymentMethod() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          due_date_from: commonHelper.formattedDate(this.dueFromToDate[0]),
          due_date_to: commonHelper.formattedDate(this.dueFromToDate[1]),
          le_id: this.leName.value,
          ou_id: this.ouName.value,
          party_id: this.party.value,
          party_type: this.partyType,
          payment_batch_id: this.batchId,
          payment_date: this.paymentDate,
          payment_group_vset: this.payGroup.value,
          payment_method_id: this.paymentMethod.value,
          payment_mode_vset: this.paymentMode,
          payment_priority: this.paymentPriority,
          payment_status_vset: this.status,
          remarks: this.remarks,
          schedule_type: this.scheduleType.value
        };
        this.loader = true;
        this.$store
          .dispatch('payable/addEditPaymentBatch', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.editMode = false;
              this.showAlert = true;
              this.isSuccess = true;
              this.batchId = response.data.data.payment_batch_id;
              this.batchName = response.data.data.payment_batch_name;
              this.status = response.data.data.payment_status_vset;
              this.responseMsg = response.data.message;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    getPaymentsByBatchPaymentId(batchPaymentId) {
      // to open payment batch valueset
      const payload = {
        batchPaymentId: batchPaymentId
      };
      this.loader = true;
      this.$store
        .dispatch('payable/getPaymentsByBatchPaymentId', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.paymentList = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected() {},
    openValueSetModal(vsetCode) {
      // to open valueset for le and ou etc.
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.leName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.parent_value_set_id = this.leName.value;
        this.depVsetParam = this.ouName.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      // to select valueset
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName.text = item.org_name;
        this.leName.value = item.org_id;
        this.ouName.text = null;
        this.ouName.value = null;
        this.getPaymentMethodsList();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName.text = item.org_name;
        this.ouName.value = item.org_id;
      } else if (this.vsetCode === 'SCHEDULE_LINE_TYPE_VSET') {
        this.scheduleType.text = item.value_meaning;
        this.scheduleType.value = item.value_code;
      } else if (this.vsetCode === 'PAY_GROUP_TYPE') {
        this.payGroup.text = item.value_meaning;
        this.payGroup.value = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT
      ) {
        this.project.text = item.value_code;
        this.project.value = item.value_set_dtl_id;
        this.getPaymentMethodsList();
      }
    },
    clear() {
      // to clear for payment batch and module
      (this.paymentBatch = {
        value: null,
        text: null
      }),
        (this.selectedModule = {
          name: null,
          id: null
        });
    },
    closeValueSetModal() {
      // to close valueset
      this.showValueSetModal = false;
    },
    getSelectedPartyCustomer(item) {
      // to method select for customer or vendor of party
      this.party.text = item.party_name;
      this.partyType = item.party_type;
      this.showPartyModal = false;
      if (this.partyType === 'CUSTOMER') {
        this.getCustomerByPartyId(item.party_id);
      } else if (this.partyType === 'VENDOR') {
        this.getPartyVendorDetails(item.party_id);
      }
    },
    showHidePartyModal(flag) {
      // to open party modal
      this.showPartyModal = flag;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    getCustomerByPartyId(partyId) {
      // to open customer details
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.party.value = response.data.data.customer_id;
          }
        });
    },
    getPartyVendorDetails(partyId) {
      // to open vendor details
      this.$store
        .dispatch('party/getPartyVenderSiteList', partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.party.value = response.data.data.vendor_id;
          }
        });
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      // this.setChildName(this.tab);
    },

    selectedModuleData(module) {
      // to select module
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showModal = false;

      this.getPaymentMethodsList();
    },

    getPaymentMethodsList() {
      // to open valueset for payment method
      const payload = {
        module_id: this.selectedModule.id,
        le_id: this.leName.value,
        receipt_mode_vset_code: this.paymentModeCode,
        project_id: this.project.value
      };

      this.loader = true;
      this.$store
        .dispatch('bankMpngMode/getPaymentMethods', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const typeList = results.map(type => {
              return {
                value: type.party_payment_method_id,
                text: type.payment_methods_name
              };
            });
            this.paymentMethodList = typeList;
            this.paymentBasedBanks = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openModuleBasedProject(moduleName) {
      if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      }
    },
    setDefaultPaymentMode() {
      const paymentMode = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.PAYMENT_MODE
      );
      paymentMode.then(key => {
        this.paymentMode = key[0].value_meaning;
        this.paymentModeCode = key[0].value_code;
      });
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case this.leName.value:
          this.leName = {
            text: null,
            value: null
          };
          break;
        case this.ouName.value:
          this.ouName = {
            text: null,
            value: null
          };
          break;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
