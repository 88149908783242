import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'error',
  watch: {
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
    searchParams() {
      if (this.searchParams) {
        this.getErrorPaymentDetails(this.searchParams);
      } else {
        this.errorData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.getErrorPaymentDetails(this.searchParams);
    }
  },
  props: ['searchParams'],
  data() {
    return {
      loader: false,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      selectAllCheckBox: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      errorData: [],
      errorFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn'
        },
        {
          key: 'beneficiary_name',
          label: 'Payee Name'
        },
        {
          key: 'payment_document_no',
          label: 'Document No.'
        },
        {
          key: 'payee_bank_account_number',
          label: 'Payee Account'
        },
        {
          key: 'payee_IFSC_CODE',
          label: 'IFSC Code'
        },
        {
          key: 'error_code'
        },
        {
          key: 'error_message'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.reprocessError();
        }
      }
    });
  },
  methods: {
    getErrorPaymentDetails(params) {
      // to get grid data
      this.loader = true;
      this.$store
        .dispatch('hostToHostBankTransfer/getErrorPaymentDetails', params)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.errorData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    reprocessError() {
      const filterData = this.errorData.filter(data => data.selectBox);
      const payments = filterData.map(elem => {
        return {
          ap_h2h_payment_id: elem.ap_h2h_payment_id,
          error_message: elem.error_message
        };
      });
      this.loader = true;
      this.$store
        .dispatch('hostToHostBankTransfer/reprocessErrorPaymentById', payments)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            // const results = response.data.data;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    checkUncheckAll() {
      // to select for all
      this.errorData = this.errorData.map(data => {
        data.selectBox = this.selectAllCheckBox;
        return data;
      });
    },
    selectBoxChecked(flag, index) {
      // to select box checked by index
      this.errorData[index].selectBox = flag;
    }
    // selectedPayment(item){ // to select payment
    //     this.h2hPaymentDetails = Object.assign({}, item);
    //     this.$emit('inprocessPaymentDetails', this.h2hPaymentDetails);
    //     // if(selectedPayment)
    // },
  }
};
