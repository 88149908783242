import commonHelper from '@/app/utility/common.helper.utility';
import paymentDetails from './paymentDetails';
import appStrings from '@/app/utility/string.utility';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
export default {
  name: 'payments',
  components: {
    paymentDetails,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication
  },
  watch: {
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
    searchParams() {
      if (this.searchParams) {
        this.getPaymentsGrid(this.searchParams);
      } else {
        this.paymentsData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage -1;
      this.getPaymentsGrid(this.searchParams);
    }
  },
  props: ['searchParams','stat'],
  data() {
    return {
      loader: false,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      selectAllCheckBox: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      paymentRowDetail: null,
      showPaymentDetailModal: false,
      totalRows: null,
      approvalStatus: { approval_status: 'DRAFT' },
      paymentsData: [],
      paymentsFields: [
        {
          key: 'initiate_approval',
          stickyColumn: true,
          class: 'stickyColumn'
        },
        // {
        //   key: 'selectBox',
        //   label: 'Select',
        //   stickyColumn: true,
        //   variant: 'info',
        //   class: 'stickyColumn'
        // },
        {
          key: 'payment_batch_name'
        },
        {
          key: 'h2h_batch_name',
          label: 'Bank Batch'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'payment_mode_meaning',
          label: 'Payment Mode'
        },
        {
          key: 'payment_method'
        },
        {
          key: 'payment_date_from'
        },
        {
          key: 'payment_date_to'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'remarks'
        }
      ],
      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      showOpenKmModal: false,
      openkmAuthToken: null,
      bankBatchId: null,
      selectedGridData: null,
      };
  },
  mounted() {
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'shared/setActionName') {
    //     const actionName = state.shared.actionName;
    //     if (actionName === 'delete') {
    //       this.getRejectingDetails();
    //     }
    //   }
    // });
   
  },
  methods: {
    getPaymentsGrid(params) {
      // to get grid data
      let payload = this.approvalStatus;
      if (params) {
        payload = Object.assign(payload, params);
      }
      this.loader = true;
      this.$store
        .dispatch(
          'hostToHostBankTransfer/getHostToHostBankTransferPaymentGrid',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.paymentsData = response.data.data.page.map(data => {
              data.selectBox = false;
              return data;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRejectingDetails() {
      // to delete grid data
      const filterData = this.paymentsData.filter(data => data.selectBox);
      const payments = filterData.map(elem => {
        return {
          h2h_payment_id: elem.h2h_payment_id,
          h2h_payment_batch_id: elem.h2h_payment_batch_id,
          select: elem.selectBox
        };
      });
      const payload = {
        h2HPaymentDetails: payments ? payments : null
      };
      this.loader = true;
      this.$store
        .dispatch('hostToHostBankTransfer/getRejectingPaymentById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getPaymentsGrid();
            // const results = response.data.data;
            // this.leName.text = results.le_name;
            // this.leName.value = results.le_id;
            // this.ouName.text = results.ou_name;
            // this.ouName.value = results.ou_id;
            // this.bankBatchName.text = results.h2h_payment_batch_name;
            // this.bankBatchName.value = results.h2h_payment_batch_id;
            // this.fromDateToDate = [
            //   new Date(results.payment_date_from),
            //   new Date(results.payment_date_to)
            // ];
            // this.paymentMethod.text = results.payment_method;
            // this.paymentMethod.value = results.payment_method_id;
            // this.paymentMode.text = results.payment_mode_meaning;
            // this.paymentMode.value = results.payment_mode_vset;
            // this.appStatus.text = results.application_status_meaning;
            // this.appStatus.value = results.application_status_vset;
            // this.paymentsData = results.h2h_bank_payments;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedPayment(item) {
      // to select payment
      this.h2hPaymentDetails = Object.assign({}, item);
      this.$emit('paymentDetails', this.h2hPaymentDetails);
    },
    checkUncheckAll() {
      // to select for all
      this.paymentsData = this.paymentsData.map(data => {
        data.selectBox = this.selectAllCheckBox;
        return data;
      });
    },
    selectBoxChecked(flag, index) {
      // to select box checked by index
      this.paymentsData[index].selectBox = flag;
    },
    rowSelected(item) {
      // to click row open modal
      this.showPaymentDetailModal = true;
      this.bankBatchId = item.h2h_payment_batch_id;
    },
    showHidePaymentDetailsModal(flag) {
      // to open payment details modal
      this.showPaymentDetailModal = flag;
    },
    showHideHistory(flag, historyType) {
      // to click initiate approval than visible button
      this.showHistory = flag;
      this.historyType = historyType;
    },
    checkOpenKmPasswordSaved() {
      // to check password save or not
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      // to get auth token for open km
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.selectedGridData = {
              ...this.selectedLeaseDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null
            };
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      // to open km modal
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      // to set token in km
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.selectedGridData = {
        ...this.selectedLeaseDetails,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.showHideHistory(true, 'uploadDocument');
    }
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
};
