import commonHelper from '@/app/utility/common.helper.utility';
import approvedPaymentDetail from './approvedPaymentDetail';
export default {
  name: 'approvedPayment',
  components: {approvedPaymentDetail},
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.getPaymentsGrid(this.searchParams);
      } else {
        this.approvedPaymentsData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage -1;
      this.getPaymentsGrid(this.searchParams);
    }
  },
  props: ['searchParams'],
  data() {
    return {
      loader: false,
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      approvalStatus: { approval_status: 'APPROVED' },
      approvedPaymentsData: [],
      approvedPaymentsFields: [
        {
          key: 'payment_batch_name'
        },
        {
          key: 'h2h_batch_name',
          label: 'Bank Batch'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'payment_mode_meaning',
          label: 'Payment Mode'
        },
        {
          key: 'payment_method'
        },
        {
          key: 'payment_date_from'
        },
        {
          key: 'payment_date_to'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'remarks'
        },
      ], 
      showApprovedPaymentDetailModal: false,
      bankBatchId: null,
    };
  },
  mounted() {
    // if (this.searchParams) {
    //     this.getPaymentsGrid(this.searchParams);
    // }
  },
  methods: {
    getPaymentsGrid(params) {
      //for grid data
      let payload = this.approvalStatus;
      if (params) {
        payload = Object.assign(payload, params);
      }
      this.loader = true;
      this.$store
        .dispatch(
          'hostToHostBankTransfer/getApprovedList',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.approvedPaymentsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedPayment(item) {
      //for select payment
      this.h2hPaymentDetails = Object.assign({}, item);
      this.$emit('rejectedPaymentDetails', this.h2hPaymentDetails);
    },
    rowSelected(item) {
      // to click row open modal
      this.showApprovedPaymentDetailModal = true;
      this.bankBatchId = item.h2h_payment_batch_id;
    },
    showHideApprovedPaymentDetailModal(flag) {
      // to open details modal
      this.showApprovedPaymentDetailModal = flag;
    },
  }
};
