import advanceSearch from './advanceSearch';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import payments from './payments';
import importExcel from './importExcel';
import inProcessPayments from './inProcessPayments';
import rejectedPayments from './rejectedPayments';
import approvedPayment from './approvedPayment';
import fileGenPending from './fileGenPending';
import processedPayments from './processedPayments';
import allPayments from './allPayments';
import error from './error';
import addHostToHostBankTransfer from './addHostToHostBankTransfer';
import { ModelSelect } from 'vue-search-select';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';

export default {
  name: 'hostToHostBankTransfer',
  components: {
    advanceSearch,
    DatePicker,
    ModelSelect,
    payments,
    importExcel,
    inProcessPayments,
    rejectedPayments,
    approvedPayment,
    fileGenPending,
    processedPayments,
    allPayments,
    error,
    addHostToHostBankTransfer,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication
  },
  watch: {
    perPage: function() {
      this.currentPage = 1;
      this.searchH2hBankBatchList();
    }
  },
  data() {
    return {
      payload: null,
      loader: false,
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      selectedGridData: null,
      showhostToHostBankTransferModal: false,
      hostToHostBankTransferDetails: null,
      parent_value_set_id: null,
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      requestParams: {},
      searchParams: {},
      showManualPaymentModal: false,
      batchName: {
        value: null,
        text: null
      },
      batchNameList: [],
      showAdvSearchModal: false,
      showValueSetModal: false,
      hostToHostBankTransferData: [],
      activeTab: 'payments',
      h2hPaymentBatchId: null,
      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      showOpenKmModal: false,
      openkmAuthToken: null,
      inprocessActive: false,
      approvalStatus: 'DRAFT'
    };
  },
  mounted() {
    this.getBankBatchLov();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHideManualPaymentModal(true);
        }
      }
    });
  },
  methods: {
    searchH2hBankBatchList() {
      // search method for data
      this.requestParams = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      if (!this.searchQueryParams) {
        this.requestParams = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.leName.value,
          ou_id: this.ouName.value,
          bank_batch_id: this.batchName.value
        };
      } else {
        this.requestParams = Object.assign(
          this.requestParams,
          this.searchQueryParams
        );
      }
      this.searchParams = this.requestParams;
    },
    selectedTab(activeTab) {
      // To buttons are vsisble according to select tab
      this.totalRows = 0;
      this.activeTab = activeTab;
      this.setApprovalStatus(activeTab);
      this.showHistoryBtn = false;
      if (activeTab === 'inprocess') {
        this.inprocessActive = true;
      } else {
        this.inprocessActive = false;
      }
    },
    paymentDetails(item) {
      if (item.h2h_payment_batch_id) {
        this.showHistoryBtn = true;
        this.h2hPaymentBatchId = item.h2h_payment_batch_id;
      }
    },
    inprocessPaymentDetails(item) {
      if (item.h2h_payment_batch_id) {
        this.showHistoryBtn = true;
        this.h2hPaymentBatchId = item.h2h_payment_batch_id;
      }
    },
    rejectedPaymentDetails(item) {
      if (item.h2h_payment_batch_id) {
        this.showHistoryBtn = true;
        this.h2hPaymentBatchId = item.h2h_payment_batch_id;
      }
    },
    approvedPaymentDetail(item) {
      if (item.h2h_payment_batch_id) {
        this.showHistoryBtn = true;
        this.h2hPaymentBatchId = item.h2h_payment_batch_id;
      }
    },
    fileGenDetails(item) {
      if (item.h2h_payment_batch_id) {
        this.showHistoryBtn = true;
        this.h2hPaymentBatchId = item.h2h_payment_batch_id;
      }
    },
    getBankBatchLov() {
      // To select bank batch
      this.loader = true;
      this.$store
        .dispatch('hostToHostBankTransfer/getBankBatchLov')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.batchNameList = results.map(key => {
              return {
                value: key.h2h_payment_batch_id,
                text: key.h2h_batch_name
              };
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      //value set open
      if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.leName.value;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      // to select valueset
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.ouName = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName = {
          value: item.org_id,
          text: item.org_name
        };
      }
    },
    closeValueSetModal() {
      // close valueset modal
      this.showValueSetModal = false;
    },
    hideHostToHostBankTransferModal(flag) {
      this.showHostToHostBankTransferModal = flag;
    },
    showHideAdvSearchModal(flag) {
      // open advance search modal
      this.showAdvSearchModal = flag;
    },
    advanceSearch(params) {
      //advance search params
      this.searchQueryParams = params;
      this.searchH2hBankBatchList();
      this.showAdvSearchModal = false;
    },
    showHideManualPaymentModal(flag) {
      // open manual payments modal
      this.showManualPaymentModal = flag;
      this.getBankBatchLov();
    },
    clearParams() {
      // to clear filters
      this.parent_value_set_id = null;
      this.leName = {
        text: null,
        value: null
      };
      this.ouName = {
        text: null,
        value: null
      };
      this.batchName = {
        text: null,
        value: null
      };
      this.searchParams = null;
      this.requestParams = {};
      this.totalRows = null;
      this.currentPage = 1;
    },
    showHideHistory(flag, historyType) {
      // open modal for buttons to click initiate approval
      this.showHistory = flag;
      this.historyType = historyType;
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.selectedGridData = {
              ...this.selectedLeaseDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null
            };
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      // To open km modal
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.selectedGridData = {
        ...this.selectedLeaseDetails,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.showHideHistory(true, 'uploadDocument');
    },
    totalRowsCount(item) {
      this.totalRows = item;
    },
    setApprovalStatus(name) {
      switch (name) {
        case 'payments':
          this.approvalStatus = 'DRAFT';
          break;
        case 'inprocess':
          this.approvalStatus = 'INPROCESS';
          break;
        case 'rejected':
          this.approvalStatus = 'REJECTED';
          break;
        case 'approved':
          this.approvalStatus = 'DRAFT';
          break;
        case 'fileGen':
          this.approvalStatus = 'APPROVED';
          break;
        case 'processed':
          this.approvalStatus = 'APPROVED';
          break;
        case 'allPayments':
          this.approvalStatus = '';
          break;
      }
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case this.leName.value:
          this.leName = {
            text: null,
            value: null
          };
          break;
        case this.ouName.value:
          this.ouName = {
            text: null,
            value: null
          };
          break;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch('hostToHostBankTransfer/saveAdvSearchFilters', null);
    // this.$store.state.hostToHostBankTransfer.hostToHostBankTransfer.adv_search = null;
  }
};
