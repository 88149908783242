import commonHelper from '@/app/utility/common.helper.utility';
export default {
    name: 'importExcel',
    components: {

    },
    data() {
        return {
            loader: false,
            unsubscribe: null,
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            currentPage: 1,
            totalRows: null,
            fileName: null,
            importExcelData: [],
            importExcelFields: [
                // {
                //     key: 'pymnt_tmp_id',
                //     class: 'd-none'
                // },
                // {
                //     key: 'file_name'
                // },
                // {
                //     key: 'vendor_number'
                // },
                // {
                //     key: 'vendor_name'
                // },
                // {
                //     key: 'beneficiary_name'
                // },
                // {
                //     key: 'employee_number'
                // },
                // {
                //     key: 'account_number'
                // },
                // {
                //     key: 'ifsc',
                //     label: 'IFSC'
                // },
                // {
                //     key: 'check_number'
                // },
                // {
                //     key: 'voucher_number',
                // },
                // {
                //     key: 'period'
                // },
                // {
                //     key: 'operating_unit'
                // },
                // {
                //     key: 'amount'
                // },
                // {
                //     key: 'state'
                // },
                // {
                //     key: 'approve_reject'
                // },
                // {
                //     key: 'created_by'
                // },
                // {
                //     key: 'creation_date'
                // },
                // {
                //     key: 'ifsc_code',
                //     label: 'IFSC Code'
                // },
                // {
                //     key: 'payment_date'
                // },
                // {
                //     key: 'return_Value'
                // },
                // {
                //     key: 'remark'
                // }, 
                // {
                //     key: 'created_by'
                // },
                // {
                //     key: 'creation_date'
                // }  
            ]
        };
    },
    mounted() { },
    methods: {
        browseFile() {
            this.$refs.file.click();
        },
        selectFile() {
            // this.selectedFilesArr.push(this.$refs.file.files);
            // this.uploadDocList.push({
            //     file_name: this.$refs.file.files[0].name,
            // });
            this.fileName = this.$refs.file.files[0].name
        },

    },
};