import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import ModulesList from '../../../../admin/responsibility/searchModule';
import party from '@/app/components/generalLedger/party';
import commonHelper from '@/app/utility/common.helper.utility';
import commonhelper from '@/app/utility/mixin';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'AdvanceSearch',
  components: {
    DatePicker,
    ModelSelect,
    ModulesList,
    party
  },
  mixins: [commonhelper],
  data() {
    return {
      loader: false,
      unsubscribe: null,
      editMode: false,
      showModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      tab: null,
      selectedModule: {
        name: null,
        id: null
      },

      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      paymentMethod: {
        value: null,
        text: null
      },
      paymentMethodList: [],
      parent_value_set_id: null,
      showValueSetModal: false,
      fromDateToDate: [],
      paymentMode: {
        value: null,
        text: null
      },
      showPartyModal: false,
      party: {
        value: null,
        text: null
      },
      partyType: null,
      paymentBatch: {
        value: null,
        text: null
      },
      paymentBatchList: [
      ],
      fromCheckNumber: null,
      toCheckNumber: null,
      beneficiaryBank: '',
    };
  },
  mounted() {
    const advFormData = this.$store.state.hostToHostBankTransfer.advSearchFilters;
    if (advFormData) {
      this.setSearchItems(advFormData);
    } else {
    this.getPaymentBatchLov();
    }
  },
  methods: {
    searchAdvanceParams() {
      const payload = {
        le_id: this.leName.value,
        ou_id: this.ouName.value, 
        payment_method_id: this.paymentMethod.value,
        payee_bank_account_number: this.beneficiaryBank,
        payment_mode_vset: this.paymentMode.value,
        party_id: this.party.value,
        from_check: this.fromCheckNumber,
        to_check: this.toCheckNumber,
        payment_date_from: commonHelper.formattedDate(this.fromDateToDate[0]),
        payment_date_to: commonHelper.formattedDate(this.fromDateToDate[1]),
        party_type: this.partyType,
        payment_batch_id: this.paymentBatch.value
      }
      this.$emit('advanceSearch', payload);
      this.$store.dispatch('hostToHostBankTransfer/saveAdvSearchFilters', this);
      // this.$store.state.hostToHostBankTransfer.hostToHostBankTransfer.adv_search = this;
    },
    getPaymentBatchLov() {
      this.loader = true;
      this.$store
        .dispatch('hostToHostBankTransfer/getPaymentBatchLov')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.paymentBatchList = results.map(key => {
              return {
                value: key.payment_batch_id,
                text: key.payment_batch_name
              }
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showModal = false;

      this.getPaymentMethodsList();
    },
    getPaymentMethodsList() {
      const payload = {
        module_id: this.selectedModule.id
      };
      this.loader = true;
      this.$store
        .dispatch('bankMpngMode/getPaymentMethods', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const typeList = results.map(type => {
              return {
                value: type.party_payment_method_id,
                text: type.payment_methods_name
              };
            });
            this.paymentMethodList = typeList;
            this.paymentBasedBanks = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.leName.value;
      }
      else {
        this.parent_value_set_id = null;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.ouName = {
          value: null,
          text: null
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (this.vsetCode === 'PAYMENT_MODE') {
        this.paymentMode.text = item.value_meaning;
        this.paymentMode.value = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getSelectedPartyCustomer(item) {
      this.party.text = item.party_name;
      this.partyType = item.party_type;
      this.showPartyModal = false;
      if (this.partyType === 'CUSTOMER') {
        this.getCustomerByPartyId(item.party_id);
      } else if (this.partyType === 'VENDOR') {
        this.getPartyVendorDetails(item.party_id);
      }
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.party.value =
              response.data.data.customer_id;
          }
        });
    },
    getPartyVendorDetails(partyId) {
      this.$store
        .dispatch('party/getPartyVenderSiteList', partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.party.value =
              response.data.data.vendor_id;
          }
        });
    },
    showHidePartyModal(flag) {
      this.showPartyModal = flag;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    clearParams() {
      this.selectedModule = {
        name: null,
        id: null
      }
      this.leName = {
        value: null,
        text: null
      };
      this.ouName = {
        value: null,
        text: null
      };
      this.paymentBatch = {
        value: null,
        text: null
      };
      this.paymentMethod = {
        value: null,
        text: null
      };
      this.fromDateToDate = null;
      this.paymentMode = {
          value: null,
          text: null
      };
      this.party = {
        value: null,
        text: null
      };
      this.partyType = null;
      this.fromCheckNumber = null;
      this.toCheckNumber = null;
      this.beneficiaryBank = null;
      this.$store.dispatch('hostToHostBankTransfer/saveAdvSearchFilters', null);
      // this.$store.state.hostToHostBankTransfer.hostToHostBankTransfer.adv_search = null;
    },
    setSearchItems(item) {
      this.selectedModule = item.selectedModule;
      this.leName = item.leName;
      this.ouName = item.ouName;
      this.fromDateToDate = item.fromDateToDate;
      this.paymentMode = item.paymentMode;
      this.party = item.party;
      this.partyType = item.partyType;
      this.paymentBatch = item.paymentBatch;
      this.paymentBatchList = item.paymentBatchList;
      this.paymentMethod = item.paymentMethod;
      this.paymentMethodList = item.paymentMethodList;
      this.fromCheckNumber = item.fromCheckNumber;
      this.toCheckNumber = item.toCheckNumber;
      this.beneficiaryBank = item.beneficiaryBank;
      
    },
    clearVsetValues(vsetCode){
      switch(vsetCode){
        case this.leName.value:
          this.leName = {
              text:null,
              value:null
          }
          break;
        case this.ouName.value:
          this.ouName = {
            text: null,
            value: null
          }
          break;
        case this.paymentMode.value:
          this.paymentMode = {
            text: null,
            value: null
          }
          break;
      }
    }
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
};
