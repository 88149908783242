import commonHelper from '@/app/utility/common.helper.utility';
import inProcessDetails from './inProcessDetails';
export default {
    components: {
        inProcessDetails
      },
    name: 'inProcessPayments',
    watch: {
        searchParams() {
            if(this.searchParams){
                this.getPaymentsGrid(this.searchParams);
            } else{
                this.inProcessPaymentsData = [];
            }
        },
        totalRows() {
          this.$emit('totalRowsCount', this.totalRows);
        },
        currentPage: function() {
          this.searchParams._page = this.currentPage -1;
          this.getPaymentsGrid(this.searchParams);
        }
    },
    props: ["searchParams"],
    data() {
        return {
            loader: false,
            unsubscribe: null,
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            currentPage: 1,
            totalRows: null,
            approvalStatus: { 'approval_status': 'INPROCESS' },
            inProcessPaymentsData: [],
            inProcessPaymentsFields: [
                {   
                    label: 'Notify Payment',
                    key: 'initiate_approval',
                    stickyColumn: true,
                    class: 'stickyColumn'
                },
                // {
                //     key: 'approval_batch'
                // },
                // {
                //     key: 'payment_batch_name',
                //     label: 'Payment Batch Name'
                // },
                // {
                //     key: 'h2h_batch_name',
                //     label: 'Bank Batch'
                // },
                // {
                //     key: 'beneficiary_name',
                //     label: 'Payee Name'
                // },
                // {
                //     key: 'address_1'
                // },
                // {
                //     key: 'address_2'
                // },
                // {
                //     key: 'address_3'
                // },
                // {
                //     key: 'pan_no',
                //     label: 'PAN No.'
                // },
                // {
                //     key: 'payment_id',
                //     class: 'd-none'
                // },
                // {
                //     key: 'payment_ref_no',
                //     label: 'PRN'
                // },
                // {
                //     key: 'payment_document_no',
                //     label: 'Document No.'
                // },
                // {
                //     key: 'payment_amount'
                // },
                // {
                //     key: 'payment_method'
                // },
                // {
                //     key: 'payee_bank_account_number',
                //     label: 'Payee Account'
                // },
                // {
                //     key: 'ifsc_code',
                //     label: 'IFSC Code'
                // },
                // {
                //     key: 'payment_date'
                // },
                // {
                //     key: 'return_value'
                // },
                // {
                //     key: 'remarks'
                // }
                {
          key: 'payment_batch_name'
        },
        {
          key: 'h2h_batch_name',
          label: 'Bank Batch'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'payment_mode_meaning',
          label: 'Payment Mode'
        },
        {
          key: 'payment_method'
        },
        {
          key: 'payment_date_from'
        },
        {
          key: 'payment_date_to'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'remarks'
        },
            ],
            showInprocessDetailModal: false,
            bankBatchId: null,
        };
    },
    mounted() {
        // if (this.searchParams) {
        //     this.getPaymentsGrid(this.searchParams);
        // }
    },
    methods: {
        getPaymentsGrid(params) { // to get grid data
            let payload = this.approvalStatus;
            if(params){
            payload = Object.assign(payload, params);
            }
            this.loader = true;
            this.$store
                .dispatch('hostToHostBankTransfer/getHostToHostBankTransferPaymentGrid', payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.inProcessPaymentsData = response.data.data.page;
                        this.totalRows = response.data.data.total_elements;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        selectedPayment(item){ // to select payment
            this.h2hPaymentDetails = Object.assign({}, item);
            this.$emit('inprocessPaymentDetails', this.h2hPaymentDetails);
            // if(selectedPayment)
        },
        rowSelected(item) {
            // to click row open modal
            this.showInprocessDetailModal = true;
            this.bankBatchId = item.h2h_payment_batch_id;
          },
          showHideInprocessDetailsModal(flag) {
            // to open details modal
            this.showInprocessDetailModal = flag;
          },
    }
};