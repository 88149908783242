import commonHelper from '@/app/utility/common.helper.utility';
import allPaymentDetails from './allPaymentDetails';
export default {
  name: 'allPayments',
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.getPaymentsGrid(this.searchParams);
      } else {
        this.allPaymentsData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage -1;
      this.getPaymentsGrid(this.searchParams);
    }
  },
  components: {
    allPaymentDetails
  },
  props: ['searchParams'],
  data() {
    return {
      loader: false,
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      approvalStatus: { approval_status: '' },
      allPaymentsData: [],
      allPaymentsFields: [
        {
          key: 'payment_batch_name'
        },
        {
          key: 'h2h_batch_name',
          label: 'Bank Batch'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'payment_mode_meaning',
          label: 'Payment Mode'
        },
        {
          key: 'payment_method'
        },
        {
          key: 'payment_date_from'
        },
        {
          key: 'payment_date_to'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'remarks'
        }
        // {
        //   key: 'beneficiary_name',
        //   label: 'Payee Name'
        // },
        // {
        //   key: 'payment_status'
        // },
        // {
        //   key: 'h2h_batch_name',
        //   label: 'H2H Bank Batch'
        // },
        // {
        //   key: 'approval_status',
        //   label: 'Bank Batch Status'
        // },
        // {
        //   key: 'address_1'
        // },
        // {
        //   key: 'address_2'
        // },
        // {
        //   key: 'address_3'
        // },
        // {
        //   key: 'pan_no',
        //   label: 'PAN No.'
        // },
        // {
        //   key: 'payment_id',
        //   class: 'd-none'
        // },
        // {
        //   key: 'status'
        // },
        // {
        //   key: 'payment_ref_no',
        //   label: 'PRN'
        // },
        // {
        //   key: 'payment_document_no',
        //   label: 'Document No.'
        // },
        // {
        //   key: 'payment_account'
        // },
        // {
        //   key: 'approved_by'
        // },
        // {
        //   key: 'approval_remarks'
        // },
        // {
        //   key: 'payment_method'
        // },
        // {
        //   key: 'payee_bank_account_number',
        //   label: 'Payee Account Num.'
        // },
        // {
        //   key: 'ifsc_code',
        //   label: 'IFSC Code'
        // },
        // {
        //   key: 'payment_date'
        // },
        // {
        //   key: 'file_name'
        // },
        // {
        //   key: 'send_to_bank'
        // },
        // {
        //   key: 'send_to_cfo',
        //   label: 'Send To CFO'
        // },
        // {
        //   key: 'reverse_file_name'
        // },
        // {
        //   key: 'utr_number',
        //   label: 'UTR Number'
        // },
        // {
        //   key: 'reverse_status'
        // },
        // {
        //   key: 'reverse_msg'
        // },
        // {
        //   key: 'bank_statement'
        // },
        // {
        //   key: 'statement_line_number'
        // }
      ],
      showAllPaymentsDetailsModal: null,
      bankBatchId: null
    };
  },
  mounted() {
    // if (this.searchParams) {
    //     this.getPaymentsGrid(this.searchParams);
    // }
  },
  methods: {
    getPaymentsGrid(params) {
      // to get payment data
      let payload = this.approvalStatus;
      if (params) {
        payload = Object.assign(payload, params);
      }
      this.loader = true;
      this.$store
        .dispatch(
          'hostToHostBankTransfer/getHostToHostBankTransferPaymentGrid',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.allPaymentsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      // to click row open modal
      this.showAllPaymentsDetailsModal = true;
      this.bankBatchId = item.h2h_payment_batch_id;
    },
    showHideAllPaymentsDetailsModal(flag) {
      // to open details modal
      this.showAllPaymentsDetailsModal = flag;
    },
  }
};
