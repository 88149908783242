import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility.js';
import processedPaymentsDetails from './processedPaymentsDetails';
export default {
  name: 'processedPayments',
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.params = this.searchParams;
        this.getProcessedPaymentDetails(this.searchParams);
      } else {
        this.processedPaymentsData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage -1;
      this.getProcessedPaymentDetails(this.searchParams);
    }
  },
  components: {
    processedPaymentsDetails
  },
  props: ['searchParams'],
  data() {
    return {
      loader: false,
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      approvalStatus: { 'approval_status': 'APPROVED' },
      processedPaymentsData: [],
      processedPaymentsFields: [
        {
          key: 'payment_batch_name'
        },
        {
          key: 'h2h_batch_name',
          label: 'Bank Batch'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'payment_mode_meaning',
          label: 'Payment Mode'
        },
        {
          key: 'payment_method'
        },
        {
          key: 'payment_date_from'
        },
        {
          key: 'payment_date_to'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'remarks'
        }
        // {
        //   key: 'h2h_batch_name',
        //   label: 'H2H Batch Name'
        // },
        // {
        //   key: 'beneficiary_name',
        //   label: 'Payee Name'
        // },
        // {
        //   key: 'address_1'
        // },
        // {
        //   key: 'address_2'
        // },
        // {
        //   key: 'address_3'
        // },
        // {
        //   key: 'pan_no',
        //   label: 'PAN No.'
        // },
        // {
        //   key: 'payment_id',
        //   class: 'd-none'
        // },
        // {
        //   key: 'payment_ref_no',
        //   label: 'PRN'
        // },
        // {
        //   key: 'payment_document_no',
        //   label: 'Document No'
        // },
        // {
        //   key: 'payment_account'
        // },
        // {
        //   key: 'payment_method'
        // },
        // {
        //   key: 'payee_bank_account_number',
        //   label: 'Payee Account No'
        // },
        // {
        //   key: 'ifsc_code',
        //   label: 'IFSC Code'
        // },
        // {
        //   key: 'pan_no',
        //   label: 'PAN No'
        // },
        // {
        //   key: 'payment_date'
        // },
        // {
        //   key: 'file_name',
        //   label: 'Payment File Name'
        // },
        // {
        //   key: 'reverse_file_name'
        // },
        // {
        //   key: 'utr_no',
        //   label: 'UTR Number'
        // },
        // {
        //   key: 'reverse_status'
        // },
        // {
        //   key: 'reverse_status_message',
        //   label: 'Reverse Msg'
        // }
      ],
      showValueSetModal: false,
      parent_value_set_id: null,
      fileName: null,
      fileId: null,
      params: null,
      showProcessedPaymentsDetailsModal: false,
      bankBatchId: null,
    };
  },
  mounted() {
    // this.getProcessedPaymentDetails();
  },
  methods: {
    getProcessedPaymentDetails(params) {
      // To Get Processed Payment Data
      let payload = this.approvalStatus;
      if(params){
      payload = Object.assign(payload, params);
      }
      // this.params = Object.assign(this.params, { file_id: this.fileId });
      this.loader = true;
      this.$store
        // .dispatch('hostToHostBankTransfer/getProcessedPaymentDetails', this.params)
        .dispatch('hostToHostBankTransfer/getProcessedList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.processedPaymentsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode = appStrings.VALUESETTYPE.H2H_BANK_FILE) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.H2H_BANK_FILE) {
        this.fileName = item.value_code;
        this.fileId = item.value_set_dtl_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearFileSearch() {
      this.fileName = null;
      this.fileId = null;
    },
    rowSelected(item) {
      // to click row open modal
      this.showProcessedPaymentsDetailsModal = true;
      this.bankBatchId = item.h2h_payment_batch_id;
    },
    showHideProcessedPaymentsDetailsModal(flag) {
      // to open details modal
      this.showProcessedPaymentsDetailsModal = flag;
    },
  }
};
